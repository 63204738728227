<template>
	<div>
		<a-modal v-model="isModalVisible" title="Registrar Ticket" @ok="handleOk">
			<a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
				<a-form-item label="Mesa">
					<a-input :value="selectedTableDescription" disabled />
				</a-form-item>
				<a-row :gutter="16">
					<a-col :xs="24" :sm="12">
						<a-form-item label="Boleta" :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }">
							<a-input placeholder="Boleta" v-decorator="[
								'Boleta',
								{ rules: [{ required: true, message: 'Ingresa la boleta' }] }
							]" />
						</a-form-item>
					</a-col>
					<a-col :xs="24" :sm="12">
						<a-form-item label="Correlativo" :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }">
							<a-input placeholder="Correlativo" v-decorator="[
								'Correlativo',
								{ rules: [{ required: true, message: 'Ingresa correlativo' },
								{ pattern: /^[0-9]*$/, message: 'Ingresa un número' },
                                { validator: checkCorrelativo, message: 'El correlativo ya fue registrado' }
								] }
							]" />
						</a-form-item>
					</a-col>
				</a-row>
				<a-form-item label="Nombres" :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }">
					<a-input placeholder="Nombres" v-decorator="[
						'Nombres',
						{ rules: [{ required: true, message: 'Ingresa nombre' },] }
					]" />
				</a-form-item>
				<a-form-item label="Apellidos" :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }">
					<a-input placeholder="Apellidos" v-decorator="[
						'Apellidos',
						{ rules: [{ required: true, message: 'Ingresa apellidos' }] }
					]" />
				</a-form-item>
				<a-form-item label="Celular" :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }">
					<a-input placeholder="Whatsapp/Celular" v-decorator="[
						'Celular',
						{
							rules: [{ required: true, message: 'Ingresa whatsapp/celular' },
							{ pattern: /^[0-9]{8}$/, message: 'Ingresa un número de 8 dígitos' }
							]
						}
					]" />
				</a-form-item>
			</a-form>
		</a-modal>

		<!-- Projects Table Card -->
		<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ padding: 0, }">
			<template #title>
				<a-row type="flex" align="middle">
					<a-col :span="24" :md="12">
						<h6>Mesas</h6>
					</a-col>
					<a-col>
						<a-input placeholder="Filtrar espacios disponibles" v-model="available_filter"
							@change="filterMesasPorEspacioDisponible" />
					</a-col>
				</a-row>
			</template>
			<a-table :columns="columns" :data-source="data" :pagination="false">

				<!-- <a-space slot="members" slot-scope="members" :size="-12" class="avatar-chips">
				<template v-for="member in members">
					<a-avatar :key="member" size="small" :src="member" />
				</template>
			</a-space> -->

				<template slot="company" slot-scope="company">
					<h6 class="m-0">
						<img :src="company.logo" width="25" class="mr-10">
						{{ company.name }}
					</h6>
				</template>

				<template slot="completion" slot-scope="completion">
					<span class="font-bold text-muted text-sm">{{ completion.label ? completion.label : completion
						}}</span>
					<a-progress :percent="completion.value" :show-info="false" size="small"
						:status="completion.status ? completion.status : 'normal'" />
				</template>

				<template slot="createticket" slot-scope="data">
					<a-button type="primary" @click="handleShowModal(data)" :disabled="data.completion.value == 100" disabled>
						Registrar Ticket
					</a-button>
				</template>

			</a-table>

		</a-card>
	</div>
</template>

<script>

export default ({
	props: {
		data: {
			type: Array,
			default: () => [],
		},
		columns: {
			type: Array,
			default: () => [],
		},
		callback:
		{
			type: Function,
			default: () => { },
		},
		mesas: {
			type: Array,
			default: () => [],
		},
	},
	mounted() {
		// this.fetchMesas()
	},
	computed:{
    tickets(){
		return this.mesas.flatMap(mesa => mesa.tickets);
		}
	},
	methods: {
		async checkCorrelativo(rule, value, callback) {
      console.log("Checking correlativo", value)
      console.log(this.tickets)
    //   const exists = this.tickets.find(ticket => ticket.Correlativo === value);
	  const exist = await this.$axios.get('/tickets?filters[Correlativo][$eq]=' + value)
	  console.log(exist)
      if (exists) {
        console.log(exists)
        rule.message = `El correlativo ya fue registrado para ${exists.Nombres} con la boleta ${exists.Boleta}`;
        callback(new Error('El correlativo ya fue registrado'));
      } else {
        callback();
      }
    },
	// async fetchMesas() {
    //   this.tickets = []
    //   try {
    //     // const response = await this.$axios.get('/mesas?populate=*'); // Replace with the actual API endpoint
    //     // this.mesas = response.data.data; // Access data from the API
    //     // this.mesas = this.mesasData;
	// 	console.log(this.mesas)
    //     this.mesas.forEach(mesa => {
    //       mesa.tickets.forEach(ticket => {
    //         this.tickets.push(ticket);
    //       });
    //     });
    //     console.log(this.tickets);
    //   } catch (error) {
    //     console.error('Error fetching data:', error);
    //   }
    // },
		filterMesasPorEspacioDisponible() {
			console.log("Filtering mesas by available space");
			console.log(this.available_filter);
			if (this.available_filter) {
				this.data = this.data.filter((mesa) => mesa.espacio_disponible >= this.available_filter);
			}
			else {
				this.callback()
			}
		},
		async handleOk(e) {
			e.preventDefault();
			let current_user = localStorage.getItem('user');


			// get mesa id from api to validate if it is available
			// if available then post ticket

			let mesa = await this.$axios.get('/mesas/' + this.selectedTableId + '?populate=*');
			console.log("Getting MESA before sabe")
			console.log(mesa.data.data)
			let mesa_capacidad = mesa.data.data.capacidad
			let mesa_tickets = mesa.data.data.tickets.length
			console.log("Capacidad de Mesa", mesa_capacidad)
			console.log("Tickets en Mesa", mesa_tickets)

			if (mesa_tickets >= mesa_capacidad) {
				this.$error({
					title: 'Error',
					content: 'La mesa seleccionada ya no tiene espacio disponible, por favor cierre esta ventana y seleccione otra mesa',
				});
				return;
			} else {
				this.form.validateFields((err, values) => {
					if (!err) {
						let formData = {
							data: {
								"mesa": this.selectedTableId,
								"Boleta": values.Boleta,
								"Nombres": values.Nombres,
								"Apellidos": values.Apellidos,
								"Correlativo": values.Correlativo,
								"celular_whatsapp": values.Celular,
								"Fecha": new Date(),
								"created_user": current_user.id,
							}
						}
						// post as json to /tickets
						this.$axios.post('/tickets', formData)
							.then((response) => {
								console.log(response)
								this.isModalVisible = false;
								this.selectedTableId = null;
								this.$success({
									title: 'Ticket registrado',
									content: 'El ticket ha sido registrado exitosamente',
								});
								this.callback();
							})
							.catch((err) => {
								// if(err.response.data){
								// 	let error = err.response.data.error;
								// 	console.log(error)
								// 	console.log(error.details.errors[0])
								// 	this.$error({
								// 		title: 'Error',
								// 		content: error.message,
								// 	});
								// }

								this.$error({
									title: 'Error',
									content: 'Ha ocurrido un error al registrar el ticket, revise que todos los campos esten ingresados y el correlativo no se repita',
								});
							});
					}
					else {
						console.log('Error')
						console.log(err)
					}
				});
			}


		},
		handleShowModal(table) {
			console.log("Opening modal for table id:", table);
			this.selectedTableId = table.documentId;
			this.isModalVisible = true;
			this.selectedTableDescription = table.company.name;

			// clear fields on modal open
			this.form.resetFields();
		},
		handleCloseModal() {
			this.isModalVisible = false;
			this.selectedTableId = null;
		},
	},
	data() {
		return {
			formLayout: 'horizontal',
			form: this.$form.createForm(this, { name: 'coordinated' }),

			// Active button for the "Projects" table's card header radio button group.
			projectHeaderBtns: 'all',
			isModalVisible: false,
			selectedTableId: null,
			selectedTableDescription: '',
			available_filter: null,
		}
	},
})

</script>