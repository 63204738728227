var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"title":"Registrar Ticket"},on:{"ok":_vm.handleOk},model:{value:(_vm.isModalVisible),callback:function ($$v) {_vm.isModalVisible=$$v},expression:"isModalVisible"}},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 5 },"wrapper-col":{ span: 12 }}},[_c('a-form-item',{attrs:{"label":"Mesa"}},[_c('a-input',{attrs:{"value":_vm.selectedTableDescription,"disabled":""}})],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"xs":24,"sm":12}},[_c('a-form-item',{attrs:{"label":"Boleta","label-col":{ span: 24 },"wrapper-col":{ span: 24 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
								'Boleta',
								{ rules: [{ required: true, message: 'Ingresa la boleta' }] }
							]),expression:"[\n\t\t\t\t\t\t\t\t'Boleta',\n\t\t\t\t\t\t\t\t{ rules: [{ required: true, message: 'Ingresa la boleta' }] }\n\t\t\t\t\t\t\t]"}],attrs:{"placeholder":"Boleta"}})],1)],1),_c('a-col',{attrs:{"xs":24,"sm":12}},[_c('a-form-item',{attrs:{"label":"Correlativo","label-col":{ span: 24 },"wrapper-col":{ span: 24 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
								'Correlativo',
								{ rules: [{ required: true, message: 'Ingresa correlativo' },
								{ pattern: /^[0-9]*$/, message: 'Ingresa un número' },
                                { validator: _vm.checkCorrelativo, message: 'El correlativo ya fue registrado' }
								] }
							]),expression:"[\n\t\t\t\t\t\t\t\t'Correlativo',\n\t\t\t\t\t\t\t\t{ rules: [{ required: true, message: 'Ingresa correlativo' },\n\t\t\t\t\t\t\t\t{ pattern: /^[0-9]*$/, message: 'Ingresa un número' },\n                                { validator: checkCorrelativo, message: 'El correlativo ya fue registrado' }\n\t\t\t\t\t\t\t\t] }\n\t\t\t\t\t\t\t]"}],attrs:{"placeholder":"Correlativo"}})],1)],1)],1),_c('a-form-item',{attrs:{"label":"Nombres","label-col":{ span: 24 },"wrapper-col":{ span: 24 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'Nombres',
						{ rules: [{ required: true, message: 'Ingresa nombre' } ] }
					]),expression:"[\n\t\t\t\t\t\t'Nombres',\n\t\t\t\t\t\t{ rules: [{ required: true, message: 'Ingresa nombre' },] }\n\t\t\t\t\t]"}],attrs:{"placeholder":"Nombres"}})],1),_c('a-form-item',{attrs:{"label":"Apellidos","label-col":{ span: 24 },"wrapper-col":{ span: 24 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'Apellidos',
						{ rules: [{ required: true, message: 'Ingresa apellidos' }] }
					]),expression:"[\n\t\t\t\t\t\t'Apellidos',\n\t\t\t\t\t\t{ rules: [{ required: true, message: 'Ingresa apellidos' }] }\n\t\t\t\t\t]"}],attrs:{"placeholder":"Apellidos"}})],1),_c('a-form-item',{attrs:{"label":"Celular","label-col":{ span: 24 },"wrapper-col":{ span: 24 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'Celular',
						{
							rules: [{ required: true, message: 'Ingresa whatsapp/celular' },
							{ pattern: /^[0-9]{8}$/, message: 'Ingresa un número de 8 dígitos' }
							]
						}
					]),expression:"[\n\t\t\t\t\t\t'Celular',\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\trules: [{ required: true, message: 'Ingresa whatsapp/celular' },\n\t\t\t\t\t\t\t{ pattern: /^[0-9]{8}$/, message: 'Ingresa un número de 8 dígitos' }\n\t\t\t\t\t\t\t]\n\t\t\t\t\t\t}\n\t\t\t\t\t]"}],attrs:{"placeholder":"Whatsapp/Celular"}})],1)],1)],1),_c('a-card',{staticClass:"header-solid h-full",attrs:{"bordered":false,"bodyStyle":{ padding: 0, }},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('a-row',{attrs:{"type":"flex","align":"middle"}},[_c('a-col',{attrs:{"span":24,"md":12}},[_c('h6',[_vm._v("Mesas")])]),_c('a-col',[_c('a-input',{attrs:{"placeholder":"Filtrar espacios disponibles"},on:{"change":_vm.filterMesasPorEspacioDisponible},model:{value:(_vm.available_filter),callback:function ($$v) {_vm.available_filter=$$v},expression:"available_filter"}})],1)],1)]},proxy:true}])},[_c('a-table',{attrs:{"columns":_vm.columns,"data-source":_vm.data,"pagination":false},scopedSlots:_vm._u([{key:"company",fn:function(company){return [_c('h6',{staticClass:"m-0"},[_c('img',{staticClass:"mr-10",attrs:{"src":company.logo,"width":"25"}}),_vm._v(" "+_vm._s(company.name)+" ")])]}},{key:"completion",fn:function(completion){return [_c('span',{staticClass:"font-bold text-muted text-sm"},[_vm._v(_vm._s(completion.label ? completion.label : completion))]),_c('a-progress',{attrs:{"percent":completion.value,"show-info":false,"size":"small","status":completion.status ? completion.status : 'normal'}})]}},{key:"createticket",fn:function(data){return [_c('a-button',{attrs:{"type":"primary","disabled":data.completion.value == 100,"disabled":""},on:{"click":function($event){return _vm.handleShowModal(data)}}},[_vm._v(" Registrar Ticket ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }